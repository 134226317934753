import React,{Component} from 'react';
import {withRouter} from './withRouter'
// import { Auth } from 'aws-amplify'
import {Navbar,NavbarBrand, NavLink, Badge,Button,
    Dropdown,DropdownMenu,DropdownItem, DropdownToggle} from 'reactstrap'
import icon_UserBadge from '../../resources/userbadge.svg';
class NavHeader extends Component
{
    constructor(props)
    {
        super(props)

        this.toggleNavbar = this.toggleNavbar.bind(this)
        this.toggle = this.toggle.bind(this)
        this.state = {
            collapsed : true,
            dropdownOpen : false,
        }

        this.goto = this.goto.bind(this)
    }

    // componentDidMount = () => {

    //     console.log(`####`)
    //     console.dir(this.props)
    // }
    
    goto = (loc) =>
    {
        this.props.router.navigate('/')
    }

    toggleNavbar = () =>
    {
        this.setState({collapsed : !this.state.collapsed})
    }

    toggle = () =>
    {
        this.setState({dropdownOpen : !this.state.dropdownOpen})
    }

    render = () => {
        let currentUser = this.props.user
        let projectTitle = this.props && 
                            this.props.router && 
                            this.props.router.location && 
                            this.props.router.location.state ? 
                            this.props.router.location.state.project.title 
                            : ''
        let currentUserName = currentUser && currentUser.attributes ? currentUser.attributes.given_name : 'Not Signed In'
        return (
            <Navbar color="light" light className="navBarCustom" sticky="top">
                    <NavbarBrand  className="mr-auto" href={`/`}>
                        <img src="/ls_logo.svg" alt="LudaSpace" style={{height: 50, width:100}}/>
                    </NavbarBrand>
                    <div className="d-flex justify-content-end align-items-center">
                        <Badge className="navBarBadge" color="dark">
                            {projectTitle}
                            </Badge>
                        <Dropdown direction="left" isOpen={this.state.dropdownOpen} 
                                  size="sm" toggle={this.toggle}>
                            
                            <DropdownToggle className="transparentToggle">
                                <img src={icon_UserBadge} width="24px" height="24px"></img>
                            </DropdownToggle>
                            <DropdownMenu>
                            <NavLink href="/projects">Current Projects</NavLink>
                            <DropdownItem divider></DropdownItem>
                            <div>Signed in as '{currentUserName}'</div>
                            <DropdownItem divider></DropdownItem>
                            <div onClick={() => this.props.signOut() }>Sign Out</div>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
             
             
            </Navbar> 
        )
    }
}

export default withRouter(NavHeader);