import * as dotenv from 'dotenv';

import axios, { AxiosInstance } from "axios";
import { Auth } from 'aws-amplify'
import { AppConfig } from '../types/AppProps';
global.Buffer = global.Buffer || require('buffer').Buffer

dotenv.config()
export const appConfig : AppConfig = {
  AppClientID: process.env.REACT_APP_CLIENTID_ONE, 
  ApiEndpoint: process.env.REACT_APP_PRIMARY_APIENDPOINT,
  ProjectsApiKey: process.env.REACT_APP_PROJECTSAPI_KEY,
  ProjectsApiPath : process.env.REACT_APP_API_PROJECTSLIST,
  ApiEndPointVersion : process.env.REACT_APP_API_CURRENTVERSION,
  VirtualWorldPath : process.env.REACT_APP_API_PROJECTVIRTUALWORLD

}
    

    const instance = async () => {

      // axios.defaults.baseURL = 'https://api.example.com';
      // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
      // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

      // let currentUser = await Auth.currentAuthenticatedUser({bypassCache: false})
      //         .then((user) => {
      //             return user;
      //         })
      //         .catch((err) => console.log(err));
      
      return axios.create({
        baseURL: appConfig.ApiEndpoint,
        timeout: 15000
      });
    }

    const authorisationHeaders = async () => {
      let session = await Auth.currentSession();
      let idToken = session.getIdToken();
      let jwtToken = idToken.getJwtToken();
      let bearer = `Bearer ${jwtToken}`

      return { 
        headers: 
        {
          "Content-type": "application/json",
          "Authorization" : bearer, 
          "x-api-key" : appConfig.ProjectsApiKey
        }
      }
    }

    const unAuthorisedHeaders = async () => {

      return { 
        headers: 
        {
          "Content-type": "application/json",
          "Authorization" : "Bearer unauthorized", 
          "x-api-key" : appConfig.ProjectsApiKey
        }
      }
    }
  
  
export {
  instance, authorisationHeaders,unAuthorisedHeaders
}
