import {Theme, ThemeProvider} from '@aws-amplify/ui-react';

    export const AppTheme: Theme = {
      name: 'blancblacktheme',
      tokens: {
        colors: {
          background: {
            primary: {
              value: 'black',
            },
            secondary: {
              value: 'black',
            },
          },
          font: {
            interactive: {
              value: 'white',
            },
          },
        //   brand: {
        //     primary: {
        //       '10': tokens.colors.teal['100'],
        //       '80': tokens.colors.teal['40'],
        //       '90': tokens.colors.teal['20'],
        //       '100': tokens.colors.teal['10'],
        //     },
        //   },
        },
        components: {
        //   button : {
        //     primary:  'black'
        //   },
          textfield: {
            color : { value : 'white'}
          },
          tabs: {
            item: {
              _focus: {
                color: {
                  value: 'white',
                },
              },
              _hover: {
                color: {
                  value: 'white',
                },
              },
              _active: {
                color: {
                  value: 'white',
                },
              },
            },
          },
        },
      },
    };
