import React from 'react'
import ProjectsService  from '../../Integration/Services/ProjectsService'
import Projects from '../Projects/Projects';
import { Auth } from 'aws-amplify';
import {Container, CardColumns,Card,Col,Row, CardHeader, CardBody, CardText,  Button} from 'reactstrap';

import {IAppComponentProps, AppState, TCurrentUser, TUserAttributes} from '../../types/IAppComponentProps'
import bbLogo from '../../static/bb_logo.png';


class Dashboard extends React.Component<IAppComponentProps,AppState,{}> {
    

    // private onPageChange : Function;
    constructor(props : IAppComponentProps)
    {
        super(props)
        this.state = {
            activePage : 0,
            currentUser : {
                attributes : { 
                    given_name : 'Not Logged In' ,
                    email : ''
                }
            }
            
        }
        this.onPageChange = this.onPageChange.bind(this)
    }

    componentDidMount = async () =>
    {
        let currentUser = await Auth.currentAuthenticatedUser({bypassCache: false})
            .then((user) => {
                return user;
            })
            .catch((err) => console.log(err));
        // console.log('=-=-=-=')
        // console.dir(currentUser)
        let ps = ProjectsService;
        
        console.log(`This is the APp Config ---- `)
        console.dir(this.props.appConfig)
        
        let client = await ps.getClientByClientID(this.props.appConfig.AppClientID);
        
        console.log(`Client ----`)
        console.dir(client)
        this.setState((state,props) => ({
            currentClient : client,
            currentUser : currentUser ? currentUser : undefined
        }), () => console.dir(this.state.currentClient))

        console.log(`down here`)
        console.dir(this.state)
        
    }



    onPageChange = (pageNumber : number) =>
    {
        console.log("Page number is changing - " + pageNumber)
        
       
        switch(Number(pageNumber))
        {
            
            case 1:
                this.props.navigate("/projects");
                break;
            default:
                this.props.navigate("/");
                break;
            
        }
        
    }

    render = () => {
        
        return (
            <Container fluid className="dashboardContainer">
                <Row>
                    <Col md={2}>
                    <img src={bbLogo} alt="BlancBlack Logo" className="launchCardLogo"/>

                    </Col>

                    <Col md={4} className="dashboardWelcomePanel cera-pro-regular d-flex align-items-center">
                        <h1>Welcome to the virtual worlds of BlancBlack, proudly presented by LudaSpace. <br/><br/></h1>
                                                
                        
                    </Col>
                    <Col md={5}>
                        <Projects   user={this.state.currentUser} 
                                    navigate={this.props.navigate} 
                                    appConfig={this.props.appConfig} />
                    </Col>
                </Row>
                
            
            </Container>           
        )
    }
}

// Dashboard.propTypes = {
    // user : PropTypes.string.isRequired
// }

export default Dashboard;