import React from 'react';
import { useLocation, useNavigate, useParams, RouteComponentProps } from 'react-router-dom';

export function withRouter<RouteComponentProps>(Component: React.FunctionComponent<RouteComponentProps>) {
    function ComponentWithRouterProp(props: ComponentProps) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();

        return <Component {...props} router={{ location, navigate, params }} />;
    }

    return ComponentWithRouterProp;
}
