import React from 'react';
import {AppProps} from './types/AppProps';
import {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom'
import {Container} from 'reactstrap'
import Dashboard from './Components/Global/Dashboard'
import NavHeader from './Components/Global/NavHeader'
import Viewer from './Components/Viewer/Viewer'
import './App.scss';

// global.Buffer = global.Buffer || require('buffer').Buffer

function App({user, signOut, appConfig } : AppProps) {
  const navigate = useNavigate();

  return (
    <div className="appContainer">
      <NavHeader user={user} signOut={signOut}/>

      <Container fluid id="innerAppContainer">
        <Routes>
          <Route path="/" element={<Dashboard user={user} navigate={navigate} appConfig={appConfig}/>} />
          <Route path="/projects" element={<Dashboard user={user} navigate={navigate} appConfig={appConfig}/>} />
          <Route path="/project/:pid/viewer" element={<Viewer appConfig={appConfig}/>} />
        </Routes>
      </Container>
    </div>
  );
}

export default App;
