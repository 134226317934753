import * as dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom'
import { AppTheme } from './Components/Global/AppTheme';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import { Authenticator, ThemeProvider,Theme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { AppConfig } from './types/AppProps';
Amplify.configure(awsExports);
dotenv.config()
const appConfig : AppConfig = {
  AppClientID: process.env.REACT_APP_CLIENTID_ONE, 
  ApiEndpoint: process.env.REACT_APP_PRIMARY_APIENDPOINT,
  ProjectsApiKey: process.env.REACT_APP_PROJECTSAPI_KEY,
  ProjectsApiPath : process.env.REACT_APP_API_PROJECTSLIST,
  ApiEndPointVersion : process.env.REACT_APP_API_CURRENTVERSION,
  VirtualWorldPath : process.env.REACT_APP_API_PROJECTVIRTUALWORLD

}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <div className="rootContainer">
    <ThemeProvider theme={AppTheme}>
    <Authenticator  initialState="signIn" 
                    // loginMechanism={['email']} 
                    signUpAttributes={['email','family_name','given_name','phone_number']}
                    variation="modal">
      {
        ({ signOut, user }) => (
          <Router>
            <App user={user} signOut={signOut} appConfig={appConfig} />
          </Router>
        )
      }
    </Authenticator>
    </ThemeProvider>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
