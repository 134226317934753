import React, {useCallback} from 'react';
// import { Button } from 'semantic-ui-react';
import {Button, Badge,Row, Col} from 'reactstrap';
import screenfull from 'screenfull';
import './Launch.css';
import clientConfig from './client.json';
import buttonImage from '../../static/launch_arrow_sml.png';
import bbLogo from '../../static/bb_logo.png';
const client: ClientJson = clientConfig as ClientJson;


class ClientJson {
  description?: string = 'description';
  title?: string = 'title';
}

interface LaunchProps {
  Launch: () => void;
}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
  
  const requestFS = async() => {
    console.log('Request FS')
    // await screenfull.request()
    props.Launch()
  }

  const exitFS = async() => {
    console.log('Exiting FS');
    await screenfull.exit();
  }

  return (
    
    <div id="launchContainer">
      <div className="launchCard position-absolute top-50 start-50 translate-middle"
          onClick={requestFS} >
        <Row>
          <Col md={4}>
            <img src={bbLogo} alt="BlancBlack Logo" className="launchCardLogo"/>
          </Col>
          <Col md={8}>
            <img src={buttonImage} 
                className="launchButton position-absolute top-50 end-0 translate-middle" 
                alt="Launch Virtual World"  
            /> 
          </Col>
            
        </Row>
      </div>
      <img alt="LudaSpace" src="/ls_logo.svg" style={{ width: 100, position: 'absolute', bottom: 35, right: 40 }} />
    </div>
    
  );
};
