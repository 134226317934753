import React, { Component } from 'react';
import { Card, CardSubtitle, CardTitle,Button,CardText, CardBody, CardImg, CardFooter} from 'reactstrap'
import ReactCardFlip from 'react-card-flip';
import { withRouter } from '../Global/withRouter'
import ProjectsService from '../../Integration/Services/ProjectsService.tsx'

import testThumb from "../../static/haven_thumb.jpg";
class ProjectCard extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            isFlipped : false
        }

        this.handleClick = this.handleClick.bind(this);
        this.launchVirtualWorld = this.launchVirtualWorld.bind(this);
    }

    handleClick = (event) => {
        event.preventDefault()
        this.setState((prevState,props) => ({isFlipped : !prevState.isFlipped}))
    }
    
    launchVirtualWorld = () => {
        this.props.router.navigate(`/project/${this.state.projectID}/viewer`,
        {
            state : this.state
        })
    }

    componentDidMount = async () => {
        this.setState((prevState,props) => ({
            projectID : this.props.project.projectID,
            project : this.props.project,
            client : this.props.client,
            clientID : this.props.client.clientID,
            virtualWorld : this.props.project.virtualWorld
        }), () => console.dir(this.state))
    }

    render () {
        const { client, project } = this.props
        return (
            
            <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal" >
                <Card className="projectCard">
                    <CardImg
                        alt="Haven NORTHBRIDGE"
                        src={project.thumbnailImageUrl}
                        top
                        width="200px"
                    />
                    <CardBody>
                        <CardTitle tag="h5" >
                            {project.title}
                        </CardTitle>
                        <CardSubtitle
                            className="mb-2 text-muted"
                            tag="h6"
                        >
                            {client.clientName}
                        </CardSubtitle>
                        <CardText>
                            { project.description }
                        </CardText>
                    </CardBody>
                    <CardFooter className="rightAlignFooter"> 
                        <Button onClick={this.handleClick}>
                            View
                        </Button>
                    </CardFooter>
                </Card>
                <Card className="projectCard projectCardBack">
                    <CardImg
                        alt="Haven NORTHBRIDGE"
                        src={project.projectImageUrl}
                        top
                        width="200px"
                        />
                    <CardBody>
                    <CardTitle tag="h5">
                        {project.title}
                    </CardTitle>
                    <CardSubtitle
                        className="mb-2 projectCardBackTitle"
                        tag="h6"
                    >
                        {client.clientName}
                    </CardSubtitle>
                    <CardText>
                        Select the "LAUNCH" button to open the Virtual World.
                    </CardText>
                    </CardBody>
                    <CardFooter className="d-flex flex-row"> 
                        <Button className="me-auto" color="danger" onClick={this.handleClick}>Close</Button>
                        <Button className="justify-content-end" color="success" onClick={this.launchVirtualWorld}>
                            Launch
                        </Button>
                    </CardFooter>
                </Card>
            </ReactCardFlip>

        )
    }
}

export default withRouter(ProjectCard)