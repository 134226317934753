
import React from 'react';
import ProjectsService from '../../Integration/Services/ProjectsService.tsx'
import ProjectCard from './ProjectCard'
import {v4 as uuidv4} from 'uuid';
import {CardGroup } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {IAppComponentProps, AppState} from '../../types/IAppComponentProps.ts'

class Projects extends React.Component<IAppComponentProps,AppState,{}> {

    constructor(props : IAppComponentProps)
    {
        super(props);

        this.state = {
            client : [],
            clientProjects : []
        }
    }


   
    componentDidMount = async () => {
        let ps = ProjectsService;
        // // DW:TODO:PROCESS.ENV
        // console.log(`This is the APp Config ---- `)
        // console.log(`Component Did Mount in Projects ----------`)
        // console.dir(this.props.client)
        // // let REACT_APP_CLIENTID_ONE = ;// 'e4e82961-547c-45ce-9b19-7bf8dd0480a6';
        let client = await ps.getClientByClientID(this.props.appConfig.AppClientID);
        
        this.setState((state,props) => ({
            client : client,
            clientProjects : client.projects
        }))
    }

    render() {
        console.log(`Render in Projects >>>>>>>>>>`)
        console.dir(this.state.clientProjects.length)
        console.log(`There are ${this.state.clientProjects.length} projects`)
        return (
            // style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}
            <CardGroup  
                      className="projectsCardColumns">
            {
                this.state.clientProjects.length > 0 && 
                this.state.clientProjects.map ?
                
                    this.state.clientProjects.map( p => {
                        return <ProjectCard client={this.state.client} 
                                            project={p} key={uuidv4()}/>
                    })
                 : 
                <Skeleton count="5" />
            }
            
            </CardGroup>
        )
    }

}

export default Projects;